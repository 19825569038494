import React from 'react';
import { useLocation } from 'react-router-dom';
import Card from '../components/Card/Card';
import './IndicatorsByCategory.css';

function IndicatorsByCategory({ index }) {
  // Get path of selected category
  const location = useLocation(); // Obtiene la ubicación actual
  const currentPath = location.pathname;

  return (

    <div className='indicators-by-category'>
      <span className='path'>HOME &gt; ECONOMIA &gt; <b>EMPLEO</b></span>
      <h1>
        {index.categories.map((category) => {
          if (category.path === currentPath) {
            return category.title;
          }
        })}
      </h1>

      <div className='grid'>
        {index.categories.map((category, i) => {
          if (category.path === currentPath) {
            return category.indicators.map((indicator, j) => {
              console.log(indicator); // Ahora sí imprime sin afectar el return
              return <Card key={j} path={indicator.path} indicatorElement={indicator} />;
            });
          }
          return null;
        })}
      </div>
    </div>
  )
}

export default IndicatorsByCategory;